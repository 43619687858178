

















































































import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";

@Observer
@Component({
  components: {
    StakeResult: () => import("../components/stake-result.vue"),
  },
})
export default class IncreaseStakeDialog extends Vue {
  @Inject() vm!: TrainingGroundViewmodel;

  walletStore = walletStore;

  get estimatedBooster() {
    return this.vm.selectingBooster.multiplier;
  }

  get estimatedAPR() {
    return this.vm.userApy.mulUnsafe(
      FixedNumber.from(this.estimatedBooster + "")
    );
  }
}
